import React from "react"
import { Accordion } from "react-bootstrap"
import { Container, Row, Col } from "react-bootstrap"
import "./Accordion.scss"
import ScrollAnimation from "react-animate-on-scroll"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const AccordianValuation = (props) => {
  return (
    <section className={`val-accordion-wrapper section_items ${props.valuation?"val_acc":""} ${props.accordianPage?props.accordianPage:""}`} id="faqs">
     <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
      <Container>
        <Row>
          <Col xl={12}>
            <h2 className="big-title">{props?.title}</h2>
            {(props.content?.data?.content || (props.content&&props.isPreviewEnabled)|| (props?.content&& props?.isNewDevDetails))&&
             <div className="para">
                <ContentModule Content={props.content?.data?.content || props.content} /> 
              </div>
            }
            <Accordion className="val-accordion">
              {props?.add_toggle?.map((item, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{item.title}</Accordion.Header>
                  <Accordion.Body>
                    {(item.content?.data?.content || (item.content&&props.isPreviewEnabled)
                    || (item?.content&& props?.isNewDevDetails)) && (
                      <ContentModule Content={item.content?.data?.content || item.content} />
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Col>
        </Row>
      </Container>
      </ScrollAnimation>
    </section>
  )
}

export default AccordianValuation
