import React from "react"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import { blog } from "../../lib/urls"
import GGFXImage from "../../modules/GGFXImage"
import dateFormat from "dateformat"
import "./PodcastSlider.scss"
import { Link } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import useDeviceMedia from "../../hooks/useDeviceMedia"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const PodcastSlider = ({ podCastData, pageData, itemData, isPreviewEnabled }) => {
  const podCastList = podCastData
  const { isMobile } = useDeviceMedia()
  return (
    <div className="podcast-news-warapper">
      <Container>
        <div className="podcast-news-component">
          <h2>{itemData?.title}</h2>
          {( itemData?.content?.data?.content || (isPreviewEnabled && itemData?.content))&&
          <p className="description">
            <ContentModule Content={itemData?.content?.data?.content || itemData?.content} />
          </p>
          }
          <CustomSlider
            noDots
            showMeThree
            showArrows
            iconchange
            slidecount={isMobile ? 1.2 : 4}
            className="podcast-news-slider"
          >
            {podCastList?.map((item, index) => {
              return (
                <ScrollAnimation
                  className="podcast-slider-section"
                  animateIn="animate__slideInUp"
                  animateOnce
                  delay={index * 100}
                  offset={100}
                >
                  <div className="podcast-slider-item">
                    <div className="image-section">
                      <Link to={`${blog}${item?.slug}/`}>
                        {item?.tile_image?.url && (
                          <GGFXImage
                            ImageSrc={item?.tile_image}
                            altText={item?.name}
                            imagetransforms={item?.ggfx_results}
                            renderer="srcSet"
                            imagename="blog.tile_image.podcasts_image"
                            strapiID={item?.strapi_id}
                          />
                        )}
                      </Link>
                    </div>
                    <Link to={`${blog}${item?.slug}/`}>
                      <p className="podcast-title">{item?.title}</p>
                    </Link>
                    <p className="podcast-time">
                      {dateFormat(item?.date, "mmm dd, yyyy")}
                    </p>
                  </div>
                </ScrollAnimation>
              )
            })}
          </CustomSlider>
        </div>
      </Container>
    </div>
  )
}

export default PodcastSlider
