import React from 'react'
import { Container } from "react-bootstrap"
import usePagination from "../../hooks/usePagination"
import InnerPagination from "../InnerPagination/InnerPagination"
import { Link } from "gatsby"
import "./PropertyDevelopers.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const PropertyDevelopers = ({developerList, moduleData}) => {

    // for pagination
  const itemsPerPage = 36
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: developerList,
    itemsPerPage,
  })
  // for pagination

  return (
    <div className="Property-developers-wrapper">
        <Container>
            <h2>{moduleData?.title}</h2>
            {(moduleData?.content?.data?.content)&&
              <p className="description">
                <ContentModule Content={moduleData?.content?.data?.content  } />
              </p>
            }
            {currentItems?.length>0?
            <div className="prop-developer-items">
            {currentItems?.map(item=>{
                const itemUrl = `/off-plan/off-plan-developers/${item?.node?.slug}/`;
                return(
                    <div className="developer-logo-list">
                        <Link to={itemUrl}>
                            <div className="image-list-section">
                                <img src={item?.node?.developer_image?.url} alt={item?.node?.developer_name}/>
                            </div>
                        </Link>
                    </div>
                )
            })} 
            </div>
            :
            null   
        }
            
        <InnerPagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={developerList?.length}
          setCurrentPage={setCurrentPage}
          listingName="Review"
        />
        </Container>
    </div>
  )
}

export default PropertyDevelopers