import React, { useState } from "react"
import { Container, Modal } from "react-bootstrap"
import "./ContactEnquires.scss"
import FormFields from "../../../static/forms/contact_form.json"
import FormFields1 from "../../../static/forms/press.json"
import FormFields2 from "../../../static/forms/partnership.json"


import DefaultForm from "../forms/default-form-module"

const {
  PreviewCTALink,
} = require("@starberry/gatsby-theme-utils/Modules/PreviewCTALinkModule")

const ContactEnquires = ({ enquiryData }) => {
  const [show, setShow] = useState(false)
  const [itemName, setItemName] = useState(null)

  const handleModal = (item) => {
      setShow(true)
      setItemName(item?.title)

    }
    const handleClose = () => {
      setShow(false)
    }
    const pageurl = typeof window !== "undefined" ? window.location.href : ""

  return (
    <div className="contact-enquires-wrapper">
      <Container>
        <div className="contact-enq-list">
          {enquiryData?.items?.map(item => {
            return (
              <div className="enquiry-list-items">
                <img src={item?.image?.url} alt={item?.title} />
                <h5 className="heading">{item?.title}</h5>
                <p className="sub-title">{item?.sub_title}</p>

              {(item?.cta?.custom_link === "$general-enquiry" ||
               item?.cta?.custom_link === "$partnerships" || item?.cta?.custom_link === "$press")  ? 
                  <a
                  onClick={()=>handleModal(item)}
                  href="javascript:void(0)"
                  className="button button-outline-grey"
                >
                  {item?.cta?.title}
                </a>
              
              :
            
                <PreviewCTALink
                  class="button button-outline-grey "
                  link={
                    item?.cta?.link
                      ? item.cta.link
                      : { external_link: item?.cta?.custom_link }
                  }
                  title={item?.cta?.title}
                  target_window={
                    item?.cta?.custom_link?.includes("http")
                      ? "new_window"
                      : item?.cta?.link?.target_window
                  }
                />
              }
              </div>
            )
          })}
        </div>
      </Container>
      <Modal
        show={show}
        // onHide={handleClose}
        backdrop="static"
        centered
        dialogClassName="modal-popup-form contact-form"
      >
        <Modal.Body>
          <div className="popup-form-wrapper contact-form">
            <div className="close-modal" onClick={() => handleClose()}>
              <i className="icon black-cancel-icon"></i>
            </div>
            <div>
              <DefaultForm
                fields={itemName === 'Press' ? FormFields1 :
                   itemName === 'Partnerships' ? FormFields2 :  FormFields}
                sourceUrl={pageurl}
                formTitle={(itemName === 'Press' || itemName === 'Partnerships')
                  ? itemName :"General Enquiry"}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ContactEnquires