import React, { useState, useEffect } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import GGFXImage from "../../modules/GGFXImage"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { Link } from "@reach/router"
import { teamURL } from "../../lib/urls"
import "./OffplanConsultant.scss"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const OffplanConsultant = ({
  itemData,
  teamData,
  pageData,
  isPreviewEnabled,
  isAreaGuideDetails,
  isCareerLanding,
}) => {
  const [teamList, setTeamList] = useState([])
  const { isMobile } = useDeviceMedia()

  useEffect(() => {
    if (isAreaGuideDetails || isCareerLanding) {
      setTeamList(teamData)
    } else {
      let randomResult = teamData?.sort(() => Math.random() - 0.5)
      setTeamList(randomResult)
    }
  }, [teamData])

  if (!teamData?.length > 0) return null

  const imageName = isCareerLanding ? "team.image.recruitment" : "team.image.consultant"

  let temMemberDesig = ""
  if (teamData?.length > 0) {
    teamData?.filter(data => {
      data.team_member?.filter(item => {
        if (
          item.designation === "Residential sales" ||
          item?.designation === "Residential leasing"
        ) {
          temMemberDesig = item.designation
        }
      })
    })
  }

  // const consultantData =teamList?.slice(0,10)
  const consultantData = isAreaGuideDetails ? teamList : teamList?.slice(0, 10)

  const ImageRenderList = ({ item, imagename }) => {
    return (
      <GGFXImage
        ImageSrc={item.node?.image || item?.image}
        altText={`${item.node?.name || item?.name}`}
        imagetransforms={item?.node?.ggfx_results || item?.ggfx_results}
        renderer="pic-src"
        imagename={imagename}
        strapiID={item?.node?.strapi_id || item?.id}
      />
    )
  }

  return (
    <div className={`offplan-consultant-wrapper ${isCareerLanding? "career-page-team":''}`}>
      <Container>
        <ScrollAnimation
          animateIn="animate__slideInUp"
          animateOnce
          delay={100}
          offset={10}
        >
          <div className="consultant-content-sections">
            <h2>{itemData?.title}</h2>
            {(itemData?.content?.data?.content ||
              (itemData?.content && isPreviewEnabled)) && (
              <p className="description">
                <ContentModule
                  Content={
                    itemData?.content?.data?.content || itemData?.content
                  }
                />
              </p>
            )}
          </div>

          <div className="slider-consultant-sec">
            <CustomSlider
              noDots
              showArrows
              showMeThree
              iconchange
              slidecount={isMobile ? 1.8 : isCareerLanding ? 4: isAreaGuideDetails ? 5 : 6}
              className="consultant-offplan-slider"
            >
              {consultantData?.map((item, index) => {
                const slug = item?.node?.slug ? item?.node?.slug : item?.slug

                return (
                  <ScrollAnimation
                    className="consultant-slider-section"
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={index * 100}
                    offset={100}
                    key={item?.node?.strapi_id || item?.id}
                  >
                    <div className="consultant-slider-item">
                      <div className="image-section">
                        <Link to={`${teamURL}${slug}/`}>
                          <ImageRenderList
                            item={item}
                            imagename={imageName}
                          />
                        </Link>
                      </div>
                      <Link to={`${teamURL}${slug}/`}>
                        <p className="consultant-name">
                          {item?.node?.name || item?.name}
                        </p>
                      </Link>
                      <p className="consultant-designation">
                        {item?.node?.designation || item?.designation}
                      </p>
                      {isAreaGuideDetails &&
                        item?.team_member
                          ?.filter(data => data.designation) // Ensure the condition returns a boolean
                          .map((data, index) => (
                            <p className="consultant-role" key={index}>
                              {data.designation}
                            </p> // Render the filtered designations
                          ))}
                    </div>
                  </ScrollAnimation>
                )
              })}
            </CustomSlider>
          </div>
        </ScrollAnimation>
      </Container>
    </div>
  )
}

export default OffplanConsultant
