import algoliasearch from "algoliasearch/lite"
import React, { useEffect, useState } from "react"
import { SEOSEARCHRESULTS } from "../../../components/seo-search-results"
import useSearchResultsOffplanSEO from "../../../hooks/useSearchResultsOffplanSEO "
import SearchResultsTemplate from "../../../templates/property-results"
import createResultsUrl from "../../../utils/property-results/createResultsUrl"
import generateFiltersfromPageData from "../../../utils/property-results/generateFiltersfromPageData"
import parseResultsUrl from "../../../utils/property-results/parseResultsUrl"
import isbot from "isbot"
import { useOffPlanAreaStore } from "../../../components/FilterSearch/offplanareastore"
export default function SearchResults(props) {
  const reset = useOffPlanAreaStore(state => state.reset)

  const [algoliadata, getAlgoliadata] = useState([])

  //the current page data are lives here
  const page_url_data = parseResultsUrl(props.location)
  // you can make default search page results filters here
  const myalgoliafilters = generateFiltersfromPageData(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["bathVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"],
    page_url_data["isResidentialOnly"],
    page_url_data["completionVal"]
  )

  // navigate to pages based on filters

  const { pageh1, introcopy } = useSearchResultsOffplanSEO(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["bathVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"],
    page_url_data["completionVal"]
  )
  let realuser = true
  useEffect(() => {
    // getAlgoliaResutls(myalgoliafilters)
    if (typeof window !== "undefined") {
      realuser =
        process.env.GATSBY_BlOCK_ALGOLIA_BOT_REQUEST == "true"
          ? isbot(navigator.userAgent)
          : false
    }

    if (realuser === false) {
      getAlgoliaResutls(myalgoliafilters)
    } else {
      // getBotSearchResult()
    }
    
  }, [props.location])

  useEffect(()=>{
    return ()=>reset()
  },[])
 
  
  const getFreeTextValue=typeof window!=="undefined"? sessionStorage.getItem("isfreetextValue"):""
  const getFreeText=typeof window!=="undefined"? sessionStorage.getItem("isfreetext"):""

  function containsSearchAreas(filters) {
    return filters.includes("search_areas:");
  }
  const hasSearchAreas = containsSearchAreas(myalgoliafilters);


  const getAlgoliaResutls = myalgoliafilters => {
    // lets run algolia search query to fetch hits, stats and number of pages
    const client = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_API_KEY
    )
    const index = client.initIndex(
      page_url_data["sortVal"]
        ? page_url_data["sortVal"]
        : page_url_data["indexVal"]
    )
    //const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME);
    // let query_filt = page_url_data['areaVal'].split("-and-")
    // let myareaquery = query_filt.map(function(area) {
    //     return area;
    // });
    index
      .search((hasSearchAreas? "":
      page_url_data['areaVal'] == (process.env.GATSBY_DEFAULT_AREA).toLowerCase() ? '' : page_url_data['areaVal']), {
        // similarQuery: myareaquery != process.env.GATSBY_DEFAULT_AREA ? myareaquery.join(' ') : '',
        filters: myalgoliafilters,
        page: page_url_data["pageVal"] - 1,
        hitsPerPage: page_url_data["layoutVal"] ? 1000 : 8,
      })
      .then(({ nbHits, page, hitsPerPage, nbPages, hits }) => {
        var myArray = {
          total: nbHits,
          current_page_number: page,
          hitsPerPage: hitsPerPage,
          number_of_pages: nbPages,
          hits: hits,
        }
        getAlgoliadata(myArray)
      })
  }

  
  return (
    <React.Fragment>
      <SearchResultsTemplate
        {...algoliadata}
        pageh1={pageh1}
        introcopy={introcopy}
        page_url_data={page_url_data}
        createResultsUrl={createResultsUrl}
        location_path={props.location.pathname}
        indexname={page_url_data["indexVal"]}
        isOffplan
        getAlgoliadata={getAlgoliadata}
        realuser={typeof window !== "undefined"&& isbot(navigator.userAgent)}
      />
    </React.Fragment>
  )
}

export const Head = props => {
  //the current page data are lives here
  const page_url_data = parseResultsUrl(props.location)

  // you can make default search page results filters here
  const myalgoliafilters = generateFiltersfromPageData(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"],
    page_url_data["completionVal"]
  )

  const { pagetitle, pagemetadesc } = useSearchResultsOffplanSEO(
    page_url_data["departmentVal"],
    page_url_data["searchtypeVal"],
    page_url_data["areaVal"],
    page_url_data["bedVal"],
    page_url_data["bathVal"],
    page_url_data["minpriceVal"],
    page_url_data["maxpriceVal"],
    page_url_data["statusVal"],
    page_url_data["buildingval"],
    page_url_data["amenities"],
    page_url_data["minsqftVal"],
    page_url_data["maxsqftVal"],
    page_url_data["newdevelopmentsVal"],
    page_url_data["completionVal"]
  )

  return (
    <SEOSEARCHRESULTS
      title={pagetitle}
      description={pagemetadesc}
      url={props.location.pathname}
    />
  )
}
