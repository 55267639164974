import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import Select from "react-select"
import usePagination from "../../hooks/usePagination"
import { teamURL, teamsURL } from "../../lib/urls"
import { capitalFirst } from "../Common/utils"
import InnerPagination from "../InnerPagination/InnerPagination"

import ScrollAnimation from "react-animate-on-scroll"
import "./TeamListing.scss"
import GGFXImage from "../../modules/GGFXImage"
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const TeamListing = ({ teamsData, strapi_id, teamCategories, searchValue,isPreviewEnabled }) => {
  let querySelectedDepOption
  if (typeof window !== "undefined") {
    const result = new URLSearchParams(window.location?.search)
    const department = result?.get("department")
    const depLabel = capitalFirst(department?.replace(/-/g, " "))
    querySelectedDepOption = {
      label: depLabel?.replace("And", "&"),
      value: department?.replace("-and-", "-&-"),
    }
  }

  let querySelectedDlangOption
  if (typeof window !== "undefined") {
    const result = new URLSearchParams(window.location?.search)
    const qlang = result?.get("language")
    querySelectedDlangOption = {
      label: capitalFirst(qlang?.replace(/-/g, " ")),
      value: qlang,
    }
  }

  let querySearch
  if (typeof window !== "undefined") {
    const result = new URLSearchParams(window.location?.search)
    let search = result?.get("name")
    querySearch = search?.replace("-"," ")
  }


  var allCategoryOption = {
    label: "All Departments",
    value: "all",
  }

  let allLangOption = {
    label: "All Languages",
    value: "all"
  }

  const [searchedValue, setSearchedValue] = useState(
    searchValue ? searchValue : ""
  )

  const [search, setSearch] = useState(querySearch ? querySearch : "")

  const [selectedOption, setSelectedOption] = useState(
    querySelectedDepOption?.value&&  querySelectedDepOption?.value!=="all"
    ? querySelectedDepOption : allCategoryOption
  )

  const [selectLangOptions, setSelectLangOptions] = useState(
    querySelectedDlangOption?.value&& querySelectedDlangOption.value!=="all"?
    querySelectedDlangOption : allLangOption )

  // search results from home page team module
  

 
  const [selectedTeams, setSelectedTeams] = useState(
    teamsData
  )

  //lang drodown options functionality
  let languageOptions=[]
  // languageOptions.push(allLangOption)
  let filterLang = teamsData
  filterLang?.filter(item=>{
    let isExistInLanguageData=item.node.Languages_Spoken?.strapi_json_value
     if(isExistInLanguageData?.length){
        isExistInLanguageData?.forEach(item=>languageOptions.push({label:item,value:item?.replace(/ /g, "-").toLowerCase()}))
     }
  })

  const uniqueLabels = {};

  const sortAlphabetical=languageOptions?.sort((a,b)=>{
      let nameA = a.label?.toUpperCase();
      let nameB = b.label?.toUpperCase();
      if(nameA > nameB){
        return 1
      }
      if(nameA < nameB){
        return -1
      }
      return 0
  })


  let languageOptionsList = sortAlphabetical.filter(item => {
    const label = item.label.trim().toLowerCase();
    
    if (!uniqueLabels[label]) {
        uniqueLabels[label] = true;
        return true;
    }
    return false;
  });

  languageOptionsList.unshift(allLangOption)
  //lang---

  let categoryOption = []
  categoryOption.push(allCategoryOption)

  teamCategories.forEach(element => {
    if (element.node.designation) {
      categoryOption.push({
        label: element.node.designation,
        value: element.node.designation?.replace(/ /g, "-").toLowerCase(),
      })
    }
  })

  const label = categoryOption.map(({ value }) => value)
  let categoriesOptionList = categoryOption.filter(
    ({ value }, index) => !label.includes(value, index + 1)
  )

  
   // for pagination
   const itemsPerPage = 12
   const { currentItems, currentPage, setCurrentPage } = usePagination({
     items: selectedTeams,
     itemsPerPage,
   })
   // for pagination


  var imagename = "team.image.tile_image"

  useEffect(()=>{
    let teamDataList=[]
    let filterdata=teamsData
    var path = "/about-us/our-team/";
    path +=  search? "?name=" + search.replace(" ", "-") : "";
    path += search? "&department=" + selectedOption.value?.replace("-&-","-and-")
    :"?department=" + selectedOption.value?.replace("-&-","-and-");
    path += "&language=" + selectLangOptions.value;

    if(searchedValue){
      filterdata = filterdata.filter((data, index) => {
        if (searchedValue) {
          if (
            data.node?.name
              ?.toLowerCase()
              ?.includes(searchedValue?.toLowerCase()) ||
            data.node?.choose_areas?.some(c =>
              c.title?.toLowerCase().includes(searchedValue?.toLowerCase())
            )
          ) {
            return data
          }
          if (data?.node?.team_member?.length > 0) {
            return data.node.team_member?.some(
              data =>
                data.designation?.toLowerCase() === searchedValue?.toLowerCase()
            )
          }
        }
      })
      setSearchedValue(false)
    }

      if(search){
        // setSearchedValue("")
        filterdata = filterdata.filter(
          (team, index) =>{
          return team.node?.name
              ?.toLowerCase()
              ?.includes(search.toLowerCase()) ||
            team.node?.choose_areas?.some(c =>
              c.title?.toLowerCase().includes(search?.toLowerCase())
            )
          }
        )
      }
     if(selectedOption.value!=="all"){
        filterdata= filterdata.filter(team => {
          if (team.node.team_member?.length > 0) {
            return team.node.team_member?.some(
              item =>
                item.designation?.replace(/ /g, "-").toLowerCase() ===
                selectedOption.value
            )
          }
        })
      }

      if(selectLangOptions.value!=="all"){
        filterdata = filterdata.filter(team => {
          const langValue=team.node?.Languages_Spoken?.strapi_json_value
          if(langValue?.length>0){
            return langValue?.some(item=> item?.replace(/ /g, "-").toLowerCase() === selectLangOptions.value )
          }
        })
      }

      if(!isPreviewEnabled){
        navigate(path)
      }
      teamDataList=filterdata
      setSelectedTeams(teamDataList)

  },[search,selectedOption, selectLangOptions])


  return (
    <div className="team-listing-wrapper">
      <Container>
        <ScrollAnimation
          className="team-list-animation"
          animateIn="animate__slideInUp"
          animateOnce
          delay={150}
          offset={100}
        >
          <div className="team-sorting-section">
            <div className="search-box">
              <input
                className="form-control search"
                placeholder="Search by Name or Location"
                // onChange={e => handleChange(e)}
                onChange={(e)=>setSearch(e.target.value)}
                value={search}
              />
              <span>
                <i className="icon grey-search-icon" />
              </span>
            </div>

            <div className="select-department">
              <Select
                options={categoriesOptionList}
                isSearchable={false}
                className={"category-select"}
                classNamePrefix={"category-select"}
                //   hideSelectedOptions={true}
                value={selectedOption}
                onChange={e => setSelectedOption(e)}
                components={{
                  DropdownIndicator: () => (
                    <i className="icon grey-down-arrow"></i>
                  ),
                  IndicatorSeparator: () => null,
                }}
              />
              {/* <span>
              <i className="icon grey-down-arrow" />
            </span> */}
            </div>


            <div className="select-language">
              <Select
                options={languageOptionsList}
                isSearchable={false}
                className={"category-select"}
                classNamePrefix={"category-select"}
                value={selectLangOptions}
                onChange={e => setSelectLangOptions(e)}
                components={{
                  DropdownIndicator: () => (
                    <i className="icon grey-down-arrow"></i>
                  ),
                  IndicatorSeparator: () => null,
                }}
              />
            </div>
          </div>
        </ScrollAnimation>
        <div className="team-listing-section">
          {search
            ? currentItems?.map((data, index) => {
                
                let processedImages = JSON.stringify({})
                if (data?.node?.imagetransforms) {
                  processedImages =
                    data?.node?.imagetransforms?.image_Transforms
                }
                return (
                  <ScrollAnimation
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={index * 100}
                    offset={100}
                    key={data.node?.strapi_id}
                  >
                    <div className="team-list">
                      <div className="image-section">
                        <Link to={`${teamURL}${data.node?.slug}/`}>
                          <GGFXImage
                            ImageSrc={data.node?.image}
                            altText={`${data.node?.name}`}
                            imagetransforms={data.node?.ggfx_results}
                            renderer="srcSet"
                            imagename={imagename}
                            strapiID={data.node?.strapi_id}
                          />
                          {/* <img src={data.node?.image?.url} alt={data.node?.title}/> */}
                        </Link>
                        <div className="image-overlay">
                          <Link to={`${teamURL}${data.node?.slug}/`}>
                            <span>
                              <i className="icon white-up-arrow" />
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="content-section">
                        <Link to={`${teamURL}${data.node?.slug}/`}>
                          <h5>{data.node.name}</h5>
                        </Link>
                        <p>{data.node.designation}</p>
                      </div>
                    </div>
                  </ScrollAnimation>
                )
              })
            : currentItems?.map((data, index) => {

                let processedImages = JSON.stringify({})
                if (data?.node?.imagetransforms) {
                  processedImages =
                    data?.node?.imagetransforms?.image_Transforms
                }

                return (
                  <ScrollAnimation
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={index * 100}
                    offset={100}
                    key={data.node?.strapi_id}
                  >
                    <div className="team-list">
                      <div className="image-section">
                        <Link to={`${teamURL}${data.node?.slug}/`}>
                          <GGFXImage
                            ImageSrc={data.node?.image}
                            altText={`${data.node?.name}`}
                            imagetransforms={data.node?.ggfx_results}
                            renderer="srcSet"
                            imagename={imagename}
                            strapiID={data.node?.strapi_id}
                          />
                          {/* <img src={data.node?.image?.url} alt={data.node?.title}/> */}
                          
                        </Link>
                        <div className="image-overlay">
                          <Link to={`${teamURL}${data.node?.slug}/`}>
                            <span>
                              <i className="icon white-up-arrow" />
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="content-section">
                        <Link to={`${teamURL}${data.node?.slug}/`}>
                          <h5>{data.node?.name}</h5>
                        </Link>
                        <p>{data.node?.designation}</p>
                      </div>
                    </div>
                  </ScrollAnimation>
                )
              })}
        </div>

        <InnerPagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={selectedTeams.length}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </div>
  )
}

export default TeamListing
